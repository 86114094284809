<template>
  <div>
      <loading v-if="Loading"/>
      <validation-observer ref="absensi" tag="form" @submit.prevent="submitChpass" v-if="!Loading">
        <b-form  @submit.prevent="submitChpass">
          
          <b-col cols="4">
            <b-form-group label="Password Baru">
              <validation-provider #default="{ errors }" name="password" rules="required|min:8">
                <b-form-input v-model="password" name="password" type="password" placeholder="Password" locale="id" now-button :state="errors.length > 0 ? false : null"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-button type="submit" variant="primary">Ubah</b-button>
          </b-col>
        </b-form>
      </validation-observer>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import axios from "axios";
import Loading from '@/templates/Loading.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _interfaces from '@/templates/_interfaces';
import Multiselect from 'vue-multiselect';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    ToastificationContent,
    Multiselect,
    axios
  },
  data() {
    return {
      Loading: false,
      password: null
    };
  },
  methods: {
    init(){
    },
    submitChpass(){
      axios.post("idp/master/user/chpass",{newpass:this.password}).then((response) => {
        if(response.data.status == 2000){
          this.$swal({
            title: response.data.message,
            icon: "success",
            background: "black",
            showCancelButton: false,
            confirmButtonColor: "#7367f0",
            cancelButtonColor: "#ea5455",
            confirmButtonText: "ok",
          });

          localStorage.clear()
          // Redirect to login page
          this.$router.push({ path: '/login' })
        }else{
          this.$swal({
            title: response.data.message,
            icon: "warning",
            background: "black",
            showCancelButton: false,
            confirmButtonColor: "#7367f0",
            cancelButtonColor: "#ea5455",
            confirmButtonText: "ok",
          });
        }
      });
    }
  },
  mounted(){
    this.init();
  },
}
</script>